import * as Yup from "yup";

const RelaxAudioSchema = Yup.object().shape({
  trackName: Yup.string().required("relaxAudios.form.trackNameValidation"),
  audioUrl: Yup.string().required("relaxAudios.form.audioUrlValidation"),
  relaxAudioCategoriesId: Yup.number().required(
    "relaxAudios.form.relaxAudioCategoriesIdValidation"
  ),
});

export default RelaxAudioSchema;
