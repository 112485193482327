import PropTypes from "prop-types";

const TipsTricks = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 33.963 36.254"
    >
      <g transform="translate(0 0)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M10.336,19.373A61.783,61.783,0,0,1,2.7,18.929c-1.745-.22-2.689-.442-2.7-.444L.132,0H20.541l.132,18.485h0c-.1.023-1.049.236-2.7.443A61.783,61.783,0,0,1,10.336,19.373ZM10.325,6.806h0c-.2,0-.643.946-1.034,1.781A6.352,6.352,0,0,1,8.63,9.834a6.35,6.35,0,0,1-1.39.252c-.913.119-1.948.253-2.01.444s.7.9,1.373,1.533a6.354,6.354,0,0,1,.982,1.015,6.351,6.351,0,0,1-.19,1.4c-.169.906-.361,1.932-.2,2.049a.12.12,0,0,0,.07.017,7.5,7.5,0,0,0,1.812-.85,6.359,6.359,0,0,1,1.269-.62,6.367,6.367,0,0,1,1.272.613l.013.007.195.106a6.209,6.209,0,0,0,1.608.727.122.122,0,0,0,.072-.018c.162-.118-.036-1.143-.21-2.048a6.353,6.353,0,0,1-.2-1.4,6.353,6.353,0,0,1,.977-1.02c.669-.633,1.428-1.35,1.365-1.541s-1.1-.32-2.013-.433a6.351,6.351,0,0,1-1.391-.244,6.35,6.35,0,0,1-.668-1.243c-.4-.832-.843-1.775-1.044-1.775Z"
            transform="translate(7.815 16.881)"
          />
          <path
            className="b"
            d="M0-.022A65.536,65.536,0,0,1,10.59-1a72.391,72.391,0,0,1,10.915.978v3.91H0Z"
            transform="translate(7.225 17.161)"
          />
          <path
            className="a"
            d="M628.722,384.049c-14.438,0-16.148,2.619-16.148,3.905s2.441,3.378,16.148,3.378c13.4,0,15.477-2.092,15.477-3.378S642.551,384.049,628.722,384.049Zm-.336.935c5.507,0,9.972.979,9.972,2.186s-4.465,2.186-9.972,2.186-9.972-.979-9.972-2.186,4.465-2.186,9.972-2.186Z"
            transform="translate(-610.236 -368.691)"
          />
          <path
            className="a"
            d="M612.6,397.629a1.453,1.453,0,0,0-.024.264c0,1.286,2.441,3.378,16.148,3.378,13.4,0,15.477-2.092,15.477-3.378a1.523,1.523,0,0,0-.023-.264h-6.117c-1.094.977-5.085,1.664-9.672,1.665s-8.58-.688-9.674-1.665Z"
            transform="translate(-610.238 -378.629)"
          />
          <path
            className="a"
            d="M676.479,343c-.283.287.152,2.292-.245,2.359s-.637-1.971-1-2.151-2.133.853-2.319.5,1.678-1.215,1.737-1.614-1.471-1.765-1.188-2.052,1.674,1.22,2.072,1.154,1.224-1.944,1.585-1.764-.643,1.969-.457,2.327,2.227.563,2.168.962S676.762,342.713,676.479,343Z"
            transform="translate(-656.616 -339.415)"
          />
          <path
            className="a"
            d="M3.056,3.065c-.242.246.13,1.959-.209,2.016S2.3,3.4,1.993,3.242.17,3.972.011,3.666,1.445,2.627,1.5,2.287.239.778.481.532s1.431,1.043,1.771.986S3.3-.143,3.606.01,3.057,1.694,3.216,2s1.9.481,1.853.822-1.771,0-2.012.243Z"
            transform="translate(0 3.903) rotate(-36.345)"
          />
          <path
            className="a"
            d="M2.795,2.8c-.221.225.119,1.792-.192,1.844s-.5-1.541-.78-1.681S.156,3.632.01,3.353,1.322,2.4,1.368,2.091.219.712.439.487s1.309.954,1.62.9S3.016-.131,3.3.009s-.5,1.539-.357,1.819,1.741.44,1.694.752-1.619,0-1.84.222Z"
            transform="translate(20.068 11.162) rotate(-36.345)"
          />
          <path
            className="a"
            d="M2.492,2.5c-.2.2.106,1.6-.171,1.644s-.444-1.374-.7-1.5S.139,3.239.009,2.99s1.17-.847,1.211-1.125S.195.635.392.434s1.167.85,1.444.8S2.69-.117,2.941.008s-.448,1.373-.318,1.622,1.553.393,1.511.671S2.689,2.3,2.492,2.5Z"
            transform="translate(27.637 7.619) rotate(-62.185)"
          />
          <path
            className="a"
            d="M3.055,3.064c-.242.246.13,1.959-.209,2.015S2.3,3.395,1.992,3.241.17,3.97.011,3.665,1.445,2.626,1.5,2.286.239.778.48.532s1.431,1.042,1.77.986S3.3-.143,3.6.01,3.055,1.693,3.214,2s1.9.481,1.852.822S3.3,2.818,3.055,3.064Z"
            transform="translate(6.667 12.215) rotate(-62.185)"
          />
        </g>
      </g>
    </svg>
  );
};

TipsTricks.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default TipsTricks;
