import React, { useEffect, useState } from "react";
import ArticlesTable from "./ArticlesTable";
import { Colors, Fonts } from "../../styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BaseInputField from "../../components/Inputs/BaseInput";
import TablePagination from "../../components/Common/TablePagination";
import { resultsPerPage } from "../../constants/pagination";
import { useQuery } from "@apollo/client";
import { GET_ARTICLES } from "../../graphql/queries";
import { useTranslation } from "react-i18next";
import ArticlesIcon from "../../components/Icons/Articles";
import ArticleForm from "./ArticleForm";

const useStyles = makeStyles(() => ({
  headerContainer: {
    margin: "40px 0",
  },
  title: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    fontWeight: 600,
    marginLeft: 25,
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputContainer: {
    width: 500,
    "& div": {
      marginTop: 0,
    },
  },
  contentContainer: {
    padding: "1rem 5rem",
    flex: 1,
    maxWidth: "70vw",
  },
  userContainer: {
    marginLeft: 300,
  },
}));

const Articles = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesData, setArticlesData] = useState([]);
  const [originalArticlesData, setOriginalArticlesData] = useState([]);
  const [maxPages, setMaxPages] = useState(0);
  const { t } = useTranslation();
  const [createArticle, setCreateArticle] = useState(false);
  const [editingArticleId, setEditingArticleId] = useState(null);
  const { data: articles, refetch: refetchArticles } = useQuery(GET_ARTICLES);

  useEffect(() => {
    document.title = t("articles.title");
  }, [t]);

  useEffect(() => {
    setOriginalArticlesData(
      articles ? JSON.parse(JSON.stringify(articles.articles)) : []
    );
    setArticlesData(
      articles ? JSON.parse(JSON.stringify(articles.articles)) : []
    );
    setMaxPages(articles ? articles.articles.length : 1);
    setCurrentPage(1);
  }, [articles]);

  useEffect(() => {
    const startIndex =
      currentPage === 1 ? 0 : (currentPage - 1) * resultsPerPage;
    const endIndex =
      currentPage === 1 ? resultsPerPage : currentPage * resultsPerPage;
    if (!searchValue.trim()) {
      setArticlesData(
        JSON.parse(
          JSON.stringify(originalArticlesData.slice(startIndex, endIndex))
        )
      );
      setMaxPages(Math.ceil(originalArticlesData.length / resultsPerPage));
    } else {
      const searchedValues = originalArticlesData.filter((data) => {
        return (
          data.title.indexOf(searchValue) > -1 ||
          (data?.articleCategories
            ? data?.articleCategories.name.indexOf(searchValue) > -1
            : false)
        );
      });
      setArticlesData(searchedValues.slice(startIndex, endIndex));
      setMaxPages(Math.ceil(searchedValues.length / resultsPerPage));
    }
  }, [currentPage, originalArticlesData, searchValue, maxPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  const handlePrevPageClick = () => {
    setCurrentPage((prevState) => prevState - 1);
  };

  const handleNextPageClick = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  const onFormSubmit = async () => {
    await refetchArticles();
    setCreateArticle(false);
    setEditingArticleId(null);
  };

  return (
    <Box className={classes.userContainer}>
      <Grid container>
        <Box className={classes.contentContainer}>
          <Grid container className={classes.headerContainer}>
            <ArticlesIcon
              height={40}
              width={40}
              color={Colors.COLOR_PRIMARY_BUTTON}
            />
            <Typography className={classes.title}>
              {createArticle
                ? t("articles.createNewHeader")
                : t("articles.header")}
            </Typography>
          </Grid>
          {createArticle || editingArticleId ? (
            <ArticleForm
              onFormSubmit={onFormSubmit}
              articleId={editingArticleId}
            />
          ) : (
            <>
              <Box className={classes.filterContainer}>
                <Box className={classes.inputContainer}>
                  <BaseInputField
                    id="search-value"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder={t("articles.searchPlaceholder")}
                  />
                </Box>
                <PrimaryButton onClick={() => setCreateArticle(true)}>
                  {t("articles.addNewButton")}
                </PrimaryButton>
              </Box>
              <ArticlesTable
                toggleEdit={(id) => setEditingArticleId(id)}
                results={articlesData.map((article) => {
                  return {
                    id: article.id,
                    title: article.title,
                    category: article?.articleCategories?.name,
                  };
                })}
              />
              <TablePagination
                prevDisabled={currentPage === 1}
                nextDisabled={currentPage >= maxPages}
                onPrevPageClick={handlePrevPageClick}
                onNextPageClick={handleNextPageClick}
              />
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default Articles;
