import { gql } from "@apollo/client";

export default gql`
  mutation CreateTipsTricksCategory(
    $id: Float!
    $name: String!
    $englishName: String!
    $iconUrl: String!
  ) {
    postTipsTricksCategories(
      newTipsTricksCategoryInput: {
        id: $id
        name: $name
        description: ""
        englishName: $englishName
        iconUrl: $iconUrl
      }
    ) {
      id
    }
  }
`;
