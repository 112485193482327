import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { Colors } from "../../styles";
import Fonts from "../../styles/Fonts";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import uploadFile from "../../rest/uploadFile";
import { AuthContext } from "../../context/auth-context";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_TIPS_TRICKS,
  UPDATE_TIPS_TRICKS,
} from "../../graphql/mutations";
import {
  GET_NEXT_AVAILABLE_TIPS_TRICKS_ID,
  GET_TIPS_TRICKS_BY_ID,
  GET_TIPS_TRICKS_CATEGORIES,
} from "../../graphql/queries";
import PropTypes from "prop-types";
import { parseErrorMessage } from "../../utils/parseGraphQLErrors";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import CustomEditor from "../../components/RichText/CustomEditor";
import htmlToEditorState from "../../utils/htmlToEditorState";
import fileUpload from "../../utils/fileUpload";
import editorStateToHtml from "../../utils/editorStateToHtml";
import TipsTricksSchema from "../../validations/tipsTricksSchema";

const useStyles = makeStyles(() => ({
  formContainer: {
    marginTop: 100,
  },
  inputContainer: {
    marginTop: 40,
    position: "relative",
  },
  input: {
    backgroundColor: Colors.COLOR_WHITE,
    border: `1px solid ${Colors.COLOR_GRAY}`,
    padding: "10px",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    width: 400,
    "& input": {
      padding: 0,
    },
  },
  inputLabel: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_BLACK,
    marginBottom: 10,
  },
  button: {
    marginTop: 60,
  },
  errorMessage: {
    fontSize: Fonts.SIZES.MESSAGE_TEXT,
    color: Colors.COLOR_RED,
    position: "absolute",
    left: 0,
    bottom: -25,
  },
  disabledButton: {
    color: Colors.COLOR_WHITE + "!important",
  },
  uploadContainer: {
    display: "flex",
  },
  uploadedFile: {
    marginLeft: 25,
    width: 50,
    maxWidth: 50,
  },
  uploadLabel: {
    maxHeight: 50,
    display: "flex",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    height: 50,
    width: 250,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.COLOR_PRIMARY_BUTTON,
    color: Colors.COLOR_WHITE,
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Colors.COLOR_PRIMARY_BUTTON,
    },
  },
  uploadField: {
    display: "none",
  },
}));

const TipsTricksForm = ({ onFormSubmit, tipsTricksId }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [fileWasUploaded, setFileWasUploaded] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const form = useRef();
  const { accessToken } = useContext(AuthContext);
  const [createTipsTricksMutation] = useMutation(CREATE_TIPS_TRICKS);
  const [updateTipsTricksMutation] = useMutation(UPDATE_TIPS_TRICKS);
  const { data: nextAvailableId } = useQuery(
    GET_NEXT_AVAILABLE_TIPS_TRICKS_ID,
    {
      fetchPolicy: "network-only",
    }
  );
  const { data: tipsTricksCategories } = useQuery(GET_TIPS_TRICKS_CATEGORIES);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [englishEditorState, setEnglishEditorState] = useState(
    EditorState.createEmpty()
  );
  const [getTipsTricksById, { data: tipsTricks }] = useLazyQuery(
    GET_TIPS_TRICKS_BY_ID,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setFileUrl(`${data.tipsTrick?.imageUrl}`);
        setEditorState(htmlToEditorState(tipsTricks?.tipsTrick?.content));
        setEnglishEditorState(
          htmlToEditorState(tipsTricks?.tipsTrick?.englishContent)
        );
      },
    }
  );

  useEffect(() => {
    if (tipsTricksId) {
      (async () => {
        await getTipsTricksById({
          variables: {
            id: tipsTricksId,
          },
        });
      })();
    }
  }, [getTipsTricksById, tipsTricksId]);

  const createTipsTricks = async (values) => {
    try {
      const fileResponse = await uploadFile(
        new FormData(form.current),
        accessToken
      );
      const uploadedFilePath = `${process.env.REACT_APP_BASE_URL}/${fileResponse.files[0].path}`;
      await createTipsTricksMutation({
        variables: {
          id: nextAvailableId.tipsTricksNextAvailableId.nextId,
          title: values.title,
          englishTitle: values.englishTitle,
          content: editorStateToHtml(editorState),
          englishContent: editorStateToHtml(englishEditorState),
          externalUrl: values.externalUrl,
          imageUrl: uploadedFilePath,
          tipsTricksCategoriesId: Number(values.tipsTricksCategoriesId),
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  const updateTipsTricks = async (values) => {
    try {
      let uploadedFilePath = tipsTricks?.tipsTrick?.imageUrl;
      if (fileWasUploaded) {
        const fileResponse = await uploadFile(
          new FormData(form.current),
          accessToken
        );
        uploadedFilePath = `${process.env.REACT_APP_BASE_URL}/${fileResponse.files[0].path}`;
      }
      await updateTipsTricksMutation({
        variables: {
          id: tipsTricksId,
          title: values.title,
          englishTitle: values.englishTitle,
          content: editorStateToHtml(editorState),
          englishContent: editorStateToHtml(englishEditorState),
          externalUrl: values.externalUrl,
          imageUrl: uploadedFilePath,
          tipsTricksCategoriesId: Number(values.tipsTricksCategoriesId),
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(error);
      console.log(parseErrorMessage(error));
    }
  };

  return (
    <Box className={classes.formContainer}>
      <Formik
        initialValues={{
          title: tipsTricks?.tipsTrick?.title ?? "",
          englishTitle: tipsTricks?.tipsTrick?.englishTitle ?? "",
          content: tipsTricks?.tipsTrick?.content ?? "",
          englishContent: tipsTricks?.tipsTrick?.englishContent ?? "",
          externalUrl: tipsTricks?.tipsTrick?.externalUrl ?? "",
          imageUrl: tipsTricks?.tipsTrick?.imageUrl ?? "",
          tipsTricksCategoriesId:
            tipsTricks?.tipsTrick?.tipsTricksCategoriesId ?? 1,
        }}
        enableReinitialize={true}
        validationSchema={TipsTricksSchema}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setSubmitting(true);
          if (tipsTricksId) {
            await updateTipsTricks(values);
          } else {
            await createTipsTricks(values);
          }
          formikHelpers.setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          isValid,
          isSubmitting,
          submitForm,
          setFieldValue,
          dirty,
        }) => (
          <Form ref={form}>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="title" className={classes.inputLabel}>
                {t("tipsTricks.form.title")}
              </InputLabel>
              <Field id="title" name="title" className={classes.input} />
              {errors.title && touched.title ? (
                <Box className={classes.errorMessage}>{t(errors.title)}</Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="englishTitle" className={classes.inputLabel}>
                {t("tipsTricks.form.englishTitle")}
              </InputLabel>
              <Field
                id="englishTitle"
                name="englishTitle"
                className={classes.input}
              />
              {errors.englishTitle && touched.englishTitle ? (
                <Box className={classes.errorMessage}>
                  {t(errors.englishTitle)}
                </Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="externalUrl" className={classes.inputLabel}>
                {t("tipsTricks.form.youtubeLink")}
              </InputLabel>
              <Field
                id="externalUrl"
                name="externalUrl"
                className={classes.input}
              />
              {errors.externalUrl && touched.externalUrl ? (
                <Box className={classes.errorMessage}>
                  {t(errors.externalUrl)}
                </Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel
                htmlFor="tipsTricksCategoriesId"
                className={classes.inputLabel}
              >
                {t("tipsTricks.form.tipsTricksCategory")}
              </InputLabel>
              <Field
                id="tipsTricksCategoriesId"
                name="tipsTricksCategoriesId"
                className={classes.input}
                as="select"
              >
                {tipsTricksCategories?.tipsTricksCategories.map(
                  (tipsTricksCategory) => (
                    <option
                      value={tipsTricksCategory.id}
                      key={tipsTricksCategory.id}
                    >
                      {tipsTricksCategory.name}
                    </option>
                  )
                )}
              </Field>
              {errors.tipsTricksCategoriesId &&
              touched.tipsTricksCategoriesId ? (
                <Box className={classes.errorMessage}>
                  {t(errors.tipsTricksCategoriesId)}
                </Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <CustomEditor
                label={t("tipsTricks.form.content")}
                editorState={editorState}
                onEditorStateChange={(editorState) =>
                  setEditorState(editorState)
                }
                toolbar={{
                  image: {
                    uploadCallback: (file) => fileUpload(file, accessToken),
                    previewImage: true,
                  },
                }}
              />
            </Box>
            <Box className={classes.inputContainer}>
              <CustomEditor
                label={t("tipsTricks.form.englishContent")}
                editorState={englishEditorState}
                onEditorStateChange={(editorState) =>
                  setEnglishEditorState(editorState)
                }
                toolbar={{
                  image: {
                    uploadCallback: (file) => fileUpload(file, accessToken),
                    previewImage: true,
                  },
                }}
              />
            </Box>
            <Box className={classes.inputContainer}>
              <Box className={classes.uploadContainer}>
                <InputLabel htmlFor="imageUrl" className={classes.uploadLabel}>
                  {t("tipsTricks.form.image")}
                </InputLabel>
                <input
                  id="imageUrl"
                  name="imageUrl"
                  type="file"
                  accept="image/*"
                  className={classes.uploadField}
                  onChange={(event) => {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        setFieldValue("imageUrl", fileReader.result);
                        setFileUrl(fileReader.result);
                        setFileWasUploaded(true);
                      }
                    };
                    if (fileReader) {
                      fileReader.readAsDataURL(event.target.files[0]);
                    }
                  }}
                />
                {fileUrl && (
                  <img
                    className={classes.uploadedFile}
                    src={fileUrl}
                    alt="uploaded file"
                  />
                )}
              </Box>
              {errors.imageUrl && touched.imageUrl ? (
                <Box className={classes.errorMessage}>{t(errors.imageUrl)}</Box>
              ) : null}
            </Box>
            <PrimaryButton
              onClick={() => submitForm()}
              className={classes.button}
              disabled={!isValid || isSubmitting || (!dirty && !tipsTricksId)}
              disabledClass={classes.disabledButton}
            >
              {tipsTricksId
                ? t("tipsTricks.form.update")
                : t("tipsTricks.form.submit")}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

TipsTricksForm.propTypes = {
  tipsTricksId: PropTypes.number,
  onFormSubmit: PropTypes.func.isRequired,
};

export default TipsTricksForm;
