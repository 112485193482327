import { gql } from "@apollo/client";

export default gql`
  query GetArticles {
    articles(filter: { include: ["articleCategories"] }) {
      id
      title
      articleCategories {
        name
      }
    }
  }
`;
