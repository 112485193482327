import { gql } from "@apollo/client";

export default gql`
  mutation UpdateTipsTricksCategory(
    $id: Float!
    $name: String!
    $englishName: String!
    $iconUrl: String!
  ) {
    patchTipsTricksCategory(
      id: $id
      tipsTricksCategoriesPartialInput: {
        name: $name
        englishName: $englishName
        iconUrl: $iconUrl
      }
    ) {
      id
    }
  }
`;
