import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RelaxAudiosTableHeader from "./RelaxAudiosTableHeader";
import RelaxAudiosTableRow from "./RelaxAudiosTableRow";
import PropTypes from "prop-types";
import { Colors, Fonts } from "../../styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: 30,
    marginBottom: 50,
  },
  emptyStateContainer: {
    padding: "10px 10px 10px 40px",
    backgroundColor: Colors.COLOR_TABLE_ROW_ODD,
  },
  text: {
    fontSize: Fonts.SIZES.TABLE_HEADING_TEXT,
  },
}));

const RelaxAudiosTable = ({ results, toggleEdit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.tableContainer}>
      <RelaxAudiosTableHeader />
      {results.length === 0 && (
        <Box className={classes.emptyStateContainer}>
          <Typography className={classes.text}>
            {t("relaxAudios.noResults")}
          </Typography>
        </Box>
      )}
      {results?.map((result) => {
        return (
          <RelaxAudiosTableRow
            toggleEdit={() => toggleEdit(result.id)}
            key={result.id}
            title={result.title}
            category={result.category}
          />
        );
      })}
    </Box>
  );
};

RelaxAudiosTable.propTypes = {
  toggleEdit: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      category: PropTypes.string,
    })
  ).isRequired,
};

export default RelaxAudiosTable;
