import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";

const htmlToEditorState = (content) => {
  if (content) {
    const blocksFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  } else return EditorState.createEmpty();
};

export default htmlToEditorState;
