import { gql } from "@apollo/client";

export default gql`
  mutation CreateArticle(
    $id: Float!
    $title: String!
    $englishTitle: String!
    $content: String!
    $englishContent: String!
    $externalUrl: String!
    $imageUrl: String!
    $articleCategoriesId: Float!
  ) {
    postArticles(
      newArticleInput: {
        id: $id
        title: $title
        englishTitle: $englishTitle
        content: $content
        englishContent: $englishContent
        externalUrl: $externalUrl
        imageUrl: $imageUrl
        articleTypesId: 1
        articleCategoriesId: $articleCategoriesId
        isCoaching: false
      }
    ) {
      id
    }
  }
`;
