import Login from "./containers/Login";
import TipsTricksCategories from "./containers/TipsTricksCategories";
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  deleteLocalStorage,
  getLocalStorageValue,
  setLocalStorageValue,
} from "./utils/localStorageUtils";
import localStorageKeys from "./constants/localStorageKeys";
import Layout from "./containers/Layout/Layout";
import { useIdleTimer } from "react-idle-timer";
import "./i18n/config";
import TipsTricks from "./containers/TipsTricks";
import ArticleCategories from "./containers/ArticleCategories";
import Articles from "./containers/Articles";
import ArticleVideos from "./containers/ArticleVideos";
import RelaxAudios from "./containers/RelaxAudios";

function App() {
  const defaultAuthContext = useContext(AuthContext);
  const [auth, setAuth] = useState(defaultAuthContext);
  const [inactive, setInactive] = useState(false);
  const onLogin = (response) => {
    setLocalStorageValue(localStorageKeys.ACCESS_TOKEN, response);
    setAuth(response);
  };

  const onLogOut = () => {
    deleteLocalStorage(localStorageKeys.ACCESS_TOKEN);
    setAuth(null);
  };

  useEffect(() => {
    setAuth(getLocalStorageValue(localStorageKeys.ACCESS_TOKEN, null));
  }, []);

  useEffect(() => {
    if (inactive) {
      setInactive(false);
      onLogOut();
    }
  }, [inactive]);

  const handleOnIdle = useCallback(() => {
    setInactive(true);
  }, []);

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: () => handleOnIdle(),
    debounce: 500,
    crossTab: true,
  });

  return (
    <AuthContext.Provider value={auth}>
      <HashRouter>
        <Layout onLogOut={onLogOut}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Login onLoginComplete={onLogin} />}
            />
            <Route
              path="/tips-tricks-categories"
              component={() =>
                auth ? <TipsTricksCategories /> : <Redirect to="/" />
              }
            />
            <Route
              path="/tips-tricks"
              component={() => (auth ? <TipsTricks /> : <Redirect to="/" />)}
            />
            <Route
              path="/article-categories"
              component={() =>
                auth ? <ArticleCategories /> : <Redirect to="/" />
              }
            />
            <Route
              path="/articles"
              component={() => (auth ? <Articles /> : <Redirect to="/" />)}
            />
            <Route
              path="/article-videos"
              component={() => (auth ? <ArticleVideos /> : <Redirect to="/" />)}
            />
            <Route
              path="/relax"
              component={() => (auth ? <RelaxAudios /> : <Redirect to="/" />)}
            />
          </Switch>
        </Layout>
      </HashRouter>
    </AuthContext.Provider>
  );
}

export default App;
