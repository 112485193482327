import { gql } from "@apollo/client";

export default gql`
  mutation CreateArticleCategory(
    $id: Float!
    $name: String!
    $englishName: String!
    $iconUrl: String!
  ) {
    postArticlesCategories(
      newArticleCategoryInput: {
        id: $id
        name: $name
        description: ""
        englishName: $englishName
        iconUrl: $iconUrl
      }
    ) {
      id
    }
  }
`;
