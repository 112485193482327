import * as Yup from "yup";

const TipsTricksSchema = Yup.object().shape({
  title: Yup.string().required("tipsTricks.form.titleValidation"),
  englishTitle: Yup.string(),
  content: Yup.string(),
  englishContent: Yup.string(),
  externalUrl: Yup.string(),
  imageUrl: Yup.string().required("tipsTricks.form.imageValidation"),
  tipsTricksCategoriesId: Yup.number().required(
    "tipsTricks.form.tipsTricksCategoryValidation"
  ),
});

export default TipsTricksSchema;
