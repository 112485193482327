const baseUrl = process.env.REACT_APP_BASE_URL;

const uploadFile = async (formData, accessToken) => {
  return await (
    await fetch(`${baseUrl}/files`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    })
  ).json();
};

export default uploadFile;
