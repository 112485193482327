import React, { useEffect, useState } from "react";
import ArticleCategoriesTable from "./ArticleCategoriesTable";
import { Colors, Fonts } from "../../styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BaseInputField from "../../components/Inputs/BaseInput";
import TablePagination from "../../components/Common/TablePagination";
import { resultsPerPage } from "../../constants/pagination";
import { useQuery } from "@apollo/client";
import { GET_ARTICLE_CATEGORIES } from "../../graphql/queries";
import { useTranslation } from "react-i18next";
import ArticlesIcon from "../../components/Icons/Articles";
import ArticleCategoriesForm from "./ArticleCategoriesForm";

const useStyles = makeStyles(() => ({
  headerContainer: {
    margin: "40px 0",
  },
  title: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    fontWeight: 600,
    marginLeft: 25,
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputContainer: {
    width: 500,
    "& div": {
      marginTop: 0,
    },
  },
  contentContainer: {
    padding: "1rem 5rem",
    flex: 1,
    maxWidth: "70vw",
  },
  userContainer: {
    marginLeft: 300,
  },
}));

const ArticleCategories = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [articleCategoriesData, setArticleCategoriesData] = useState([]);
  const [originalArticleCategoriesData, setOriginalArticleCategoriesData] =
    useState([]);
  const [maxPages, setMaxPages] = useState(0);
  const [createCategory, setCreateCategory] = useState(false);
  const { t } = useTranslation();
  const [editingArticleCategoriesId, setEditingArticleCategoriesId] =
    useState(null);
  const { data: articleCategories, refetch: refetchArticleCategories } =
    useQuery(GET_ARTICLE_CATEGORIES);

  useEffect(() => {
    document.title = t("articleCategories.title");
  }, [t]);

  useEffect(() => {
    setOriginalArticleCategoriesData(
      articleCategories
        ? JSON.parse(JSON.stringify(articleCategories.articleCategories))
        : []
    );
    setArticleCategoriesData(
      articleCategories
        ? JSON.parse(JSON.stringify(articleCategories.articleCategories))
        : []
    );
    setMaxPages(
      articleCategories ? articleCategories.articleCategories.length : 1
    );
    setCurrentPage(1);
  }, [articleCategories]);

  useEffect(() => {
    const startIndex =
      currentPage === 1 ? 0 : (currentPage - 1) * resultsPerPage;
    const endIndex =
      currentPage === 1 ? resultsPerPage : currentPage * resultsPerPage;
    if (!searchValue.trim()) {
      setArticleCategoriesData(
        JSON.parse(
          JSON.stringify(
            originalArticleCategoriesData.slice(startIndex, endIndex)
          )
        )
      );
      setMaxPages(
        Math.ceil(originalArticleCategoriesData.length / resultsPerPage)
      );
    } else {
      const searchedValues = originalArticleCategoriesData.filter((data) => {
        return data.name.indexOf(searchValue) > -1;
      });
      setArticleCategoriesData(searchedValues.slice(startIndex, endIndex));
      setMaxPages(Math.ceil(searchedValues.length / resultsPerPage));
    }
  }, [currentPage, originalArticleCategoriesData, searchValue, maxPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  const handlePrevPageClick = () => {
    setCurrentPage((prevState) => prevState - 1);
  };

  const handleNextPageClick = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  const onFormSubmit = async () => {
    await refetchArticleCategories();
    setCreateCategory(false);
    setEditingArticleCategoriesId(null);
  };

  return (
    <Box className={classes.userContainer}>
      <Grid container>
        <Box className={classes.contentContainer}>
          <Grid container className={classes.headerContainer}>
            <ArticlesIcon
              height={40}
              width={40}
              color={Colors.COLOR_PRIMARY_BUTTON}
            />
            <Typography className={classes.title}>
              {createCategory
                ? t("articleCategories.createNewHeader")
                : t("articleCategories.header")}
            </Typography>
          </Grid>
          {createCategory || editingArticleCategoriesId ? (
            <ArticleCategoriesForm
              onFormSubmit={onFormSubmit}
              articleCategoryId={editingArticleCategoriesId}
            />
          ) : (
            <>
              <Box className={classes.filterContainer}>
                <Box className={classes.inputContainer}>
                  <BaseInputField
                    id="search-value"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder={t("articleCategories.searchPlaceholder")}
                  />
                </Box>
                <PrimaryButton onClick={() => setCreateCategory(true)}>
                  {t("articleCategories.addNewButton")}
                </PrimaryButton>
              </Box>
              <ArticleCategoriesTable
                toggleEdit={(id) => setEditingArticleCategoriesId(id)}
                results={articleCategoriesData.map((articleCategory) => {
                  return {
                    id: articleCategory.id,
                    name: articleCategory.name,
                    englishName: articleCategory.englishName,
                    iconUrl: articleCategory.iconUrl,
                    description: articleCategory.description,
                  };
                })}
              />
              <TablePagination
                prevDisabled={currentPage === 1}
                nextDisabled={currentPage >= maxPages}
                onPrevPageClick={handlePrevPageClick}
                onNextPageClick={handleNextPageClick}
              />
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default ArticleCategories;
