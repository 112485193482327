import * as Yup from "yup";

const ArticleCategoriesSchema = Yup.object().shape({
  name: Yup.string().required("articleCategories.form.nameValidation"),
  englishName: Yup.string().required(
    "articleCategories.form.englishNameValidation"
  ),
  iconUrl: Yup.string().required("articleCategories.form.iconUrlValidation"),
});

export default ArticleCategoriesSchema;
