import { gql } from "@apollo/client";

export default gql`
  query GetArticleVideoById($id: Float!) {
    articleVideo(id: $id) {
      id
      articleCategoriesId
      title
      videoUrl
    }
  }
`;
