import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArticleCategoriesTableHeader from "./ArticleCategoriesTableHeader";
import ArticleCategoriesTableRow from "./ArticleCategoriesTableRow";
import PropTypes from "prop-types";
import { Colors, Fonts } from "../../styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: 30,
    marginBottom: 50,
  },
  emptyStateContainer: {
    padding: "10px 10px 10px 40px",
    backgroundColor: Colors.COLOR_TABLE_ROW_ODD,
  },
  text: {
    fontSize: Fonts.SIZES.TABLE_HEADING_TEXT,
  },
}));

const ArticleCategoriesTable = ({ results, toggleEdit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.tableContainer}>
      <ArticleCategoriesTableHeader />
      {results.length === 0 && (
        <Box className={classes.emptyStateContainer}>
          <Typography className={classes.text}>
            {t("articleCategories.noResults")}
          </Typography>
        </Box>
      )}
      {results?.map((result) => {
        return (
          <ArticleCategoriesTableRow
            toggleEdit={() => toggleEdit(result.id)}
            key={result.id}
            name={result.name}
            englishName={result.englishName}
            iconUrl={result.iconUrl}
          />
        );
      })}
    </Box>
  );
};

ArticleCategoriesTable.propTypes = {
  toggleEdit: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      englishName: PropTypes.string,
      iconUrl: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default ArticleCategoriesTable;
