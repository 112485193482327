import React, { useEffect } from "react";
import { Box, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { Colors } from "../../styles";
import Fonts from "../../styles/Fonts";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_RELAX_AUDIO,
  UPDATE_RELAX_AUDIO,
} from "../../graphql/mutations";
import {
  GET_RELAX_AUDIO_BY_ID,
  GET_RELAX_AUDIO_CATEGORIES,
} from "../../graphql/queries";
import PropTypes from "prop-types";
import { parseErrorMessage } from "../../utils/parseGraphQLErrors";
import formatYouTubeLinks from "../../utils/formatYouTubeLinks";
import RelaxAudioSchema from "../../validations/relaxAudioSchema";

const useStyles = makeStyles(() => ({
  formContainer: {
    marginTop: 100,
  },
  inputContainer: {
    marginTop: 40,
    position: "relative",
  },
  input: {
    backgroundColor: Colors.COLOR_WHITE,
    border: `1px solid ${Colors.COLOR_GRAY}`,
    padding: "10px",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    width: 400,
    "& input": {
      padding: 0,
    },
  },
  inputLabel: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_BLACK,
    marginBottom: 10,
  },
  button: {
    marginTop: 60,
  },
  errorMessage: {
    fontSize: Fonts.SIZES.MESSAGE_TEXT,
    color: Colors.COLOR_RED,
    position: "absolute",
    left: 0,
    bottom: -25,
  },
  disabledButton: {
    color: Colors.COLOR_WHITE + "!important",
  },
}));

const RelaxAudiosForm = ({ onFormSubmit, relaxAudioId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [createRelaxAudioMutation] = useMutation(CREATE_RELAX_AUDIO);
  const [updateRelaxAudioMutation] = useMutation(UPDATE_RELAX_AUDIO);
  const [getRelaxAudioById, { data: relaxAudio }] = useLazyQuery(
    GET_RELAX_AUDIO_BY_ID
  );
  const { data: relaxAudioCategories } = useQuery(GET_RELAX_AUDIO_CATEGORIES);

  useEffect(() => {
    if (relaxAudioId) {
      (async () => {
        await getRelaxAudioById({
          variables: {
            id: relaxAudioId,
          },
        });
      })();
    }
  }, [getRelaxAudioById, relaxAudioId]);

  const createRelaxAudio = async (values) => {
    try {
      await createRelaxAudioMutation({
        variables: {
          trackName: values.trackName,
          audioUrl: formatYouTubeLinks(values.audioUrl),
          relaxAudioCategoriesId: Number(values.relaxAudioCategoriesId),
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  const updateRelaxAudio = async (values) => {
    try {
      await updateRelaxAudioMutation({
        variables: {
          id: relaxAudioId,
          trackName: values.trackName,
          audioUrl: formatYouTubeLinks(values.audioUrl),
          relaxAudioCategoriesId: Number(values.relaxAudioCategoriesId),
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  return (
    <Box className={classes.formContainer}>
      <Formik
        initialValues={{
          trackName: relaxAudio?.relaxAudio?.trackName ?? "",
          audioUrl: relaxAudio?.relaxAudio?.audioUrl ?? "",
          relaxAudioCategoriesId:
            relaxAudio?.relaxAudio?.relaxAudioCategoriesId ?? 1,
        }}
        enableReinitialize={true}
        validationSchema={RelaxAudioSchema}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setSubmitting(true);
          if (relaxAudioId) {
            await updateRelaxAudio(values);
          } else {
            await createRelaxAudio(values);
          }
          formikHelpers.setSubmitting(false);
        }}
      >
        {({ errors, touched, isValid, isSubmitting, submitForm, dirty }) => (
          <Form>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="trackName" className={classes.inputLabel}>
                {t("relaxAudios.form.trackName")}
              </InputLabel>
              <Field
                id="trackName"
                name="trackName"
                className={classes.input}
              />
              {errors.trackName && touched.trackName ? (
                <Box className={classes.errorMessage}>
                  {t(errors.trackName)}
                </Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="audioUrl" className={classes.inputLabel}>
                {t("relaxAudios.form.audioUrl")}
              </InputLabel>
              <Field id="audioUrl" name="audioUrl" className={classes.input} />
              {errors.audioUrl && touched.audioUrl ? (
                <Box className={classes.errorMessage}>{t(errors.audioUrl)}</Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel
                htmlFor="relaxAudioCategoriesId"
                className={classes.inputLabel}
              >
                {t("relaxAudios.form.relaxAudioCategoriesId")}
              </InputLabel>
              <Field
                id="relaxAudioCategoriesId"
                name="relaxAudioCategoriesId"
                className={classes.input}
                as="select"
              >
                {relaxAudioCategories?.relaxAudioCategories.map(
                  (relaxAudioCategory) => (
                    <option
                      value={relaxAudioCategory.id}
                      key={relaxAudioCategory.id}
                    >
                      {relaxAudioCategory.name}
                    </option>
                  )
                )}
              </Field>
              {errors.relaxAudioCategoriesId &&
              touched.relaxAudioCategoriesId ? (
                <Box className={classes.errorMessage}>
                  {t(errors.relaxAudioCategoriesId)}
                </Box>
              ) : null}
            </Box>
            <PrimaryButton
              onClick={() => submitForm()}
              className={classes.button}
              disabled={!isValid || isSubmitting || (!dirty && !relaxAudioId)}
              disabledClass={classes.disabledButton}
            >
              {relaxAudioId
                ? t("relaxAudios.form.update")
                : t("relaxAudios.form.submit")}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

RelaxAudiosForm.propTypes = {
  relaxAudioId: PropTypes.number,
  onFormSubmit: PropTypes.func.isRequired,
};

export default RelaxAudiosForm;
