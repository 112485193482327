import { gql } from "@apollo/client";

export default gql`
  mutation CreateRelaxAudio(
    $audioUrl: String!
    $relaxAudioCategoriesId: Float!
    $trackName: String!
  ) {
    postRelaxAudios(
      newRelaxAudiosInput: {
        audioUrl: $audioUrl
        relaxAudioCategoriesId: $relaxAudioCategoriesId
        trackName: $trackName
      }
    ) {
      id
    }
  }
`;
